import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../style-index.css";
import Image from "../../src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>Community</h1>
    <p>
  {" "}
  <span>Administrators</span> <span>Coaches</span> <span>Entrepreneurs</span> <span>Mentors</span>
    </p>
    <p>{`The Community is where you gather with others and help each other. Is the place for you to find support, events, announcement and any other activity.`}</p>
    <h2 {...{
      "id": "info-page"
    }}>{`Info Page`}</h2>
    <p>{`We know that as per program you change some of the main information on how the program runs. Info Page allows you to centralize the information into one place.`}</p>
    <p>{`Info page is available in the left menu.`}</p>
    <p>{`This page can be seen by everyone but edited only by Administrators.`}</p>
    <p>
  In your right side you will have the <span>Edit this page</span> option, when
  clicking there the page converts to editing mode. All formatting is based on
  simple markdown to make it easier to create the page. These are the options:
    </p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Page Title:`}</strong>{` focus on the Program Name`}</li>
      <li parentName="ul"><strong parentName="li">{`Page Tagline:`}</strong>{` is a subtitle to represent what is the program about.`}</li>
      <li parentName="ul"><strong parentName="li">{`Description:`}</strong>{` In this area you will write the program description. Refers to this link for a markdown cheatsheet`}</li>
      <li parentName="ul"><strong parentName="li">{`Social Networks:`}</strong>{` LinkedIn, Twitter, Facebook, Website.`}</li>
      <li parentName="ul"><strong parentName="li">{`Add Section:`}</strong>{` allows you to add two sections:`}</li>
      <li parentName="ul"><strong parentName="li">{`Text Box:`}</strong>{` a markdown text.`}</li>
      <li parentName="ul"><strong parentName="li">{`Video:`}</strong>{` a link to a YouTube video.`}</li>
    </ul>
    <p><strong parentName="p">{`Images upload:`}</strong>{` in markdown you are not allow to upload an image but you need to add a link to an image online. The recommended place to upload you image is in `}<a parentName="p" {...{
        "href": "https://imgur.com/"
      }}>{`https://imgur.com/`}</a>{` so that then you can linked in.
If you want your pictures to be confidential, please contact our team who is going to upload it into a more secure location.`}</p>
    <p><strong parentName="p">{`Public link (coming):`}</strong>{` this page will be able to be shared outside for others to look at the program type. Embed in your landing page or use this link as your landing page for the program.`}</p>
    <h2 {...{
      "id": "home-coming"
    }}>{`Home (coming)`}</h2>
    <p>{`In your home you will have a summary of what is going on in the community, and information of your teams, tasks, mentors, coaches and startups depending on the user type that you are.`}</p>
    <p>{`Everyone has access to the Home as their main page.`}</p>
    <h2 {...{
      "id": "announcements"
    }}>{`Announcements`}</h2>
    <p>{`Creating announcements allows the community to be engaged. The announcements show the program is active and provide a better engagement to everyone.`}</p>
    <p>{`Announcements can be created by Administrators and Coaches.`}</p>
    <p>{`There are two ways to create Announcements: one is from the Home page (coming soon) and the other one is in the Events page.`}</p>
    <h3>Events Page</h3>
    <p>{`Browse to the Events page by clicking in Events on the left menu.
In the Events page you can create a new Event in two different ways, by clicking in New Event or on the date that you want to book the Event.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Type:`}</strong>{` Announcement`}</li>
      <li parentName="ul"><strong parentName="li">{`Program:`}</strong>{` in which program this event will be visible.`}</li>
      <li parentName="ul"><strong parentName="li">{`Subject:`}</strong>{` Event Title.`}</li>
      <li parentName="ul"><strong parentName="li">{`Date:`}</strong>{` select the date for your new announcement to show up. In the case that you chosen already the date then you are done..`}</li>
      <li parentName="ul"><strong parentName="li">{`Duration:`}</strong>{` how long do you want the event duration to be.`}</li>
      <li parentName="ul"><strong parentName="li">{`Topic:`}</strong>{` this is the tag that you identify later engagement per user. It is very important to have a clear tagging here.`}</li>
      <li parentName="ul"><strong parentName="li">{`Description:`}</strong>{` what is the announcement about.`}</li>
    </ul>
    <p>{`The entries with (`}{`*`}{`) are mandatory to be completed.`}</p>
    <Image src="https://res.cloudinary.com/dnuwcxti0/image/upload/v1610583673/teamcoaches/documentation/Announcements1_kuqg1a.gif" alt="Announcements" mdxType="Image" />
    <h3>Home Page</h3>
Browse to the Home Page and in the top you will have access to a bar where you can create an announcement to be shown in this page.
    <h2 {...{
      "id": "community-finder"
    }}>{`Community Finder`}</h2>
    <p>{`Looking for people with common interest, advice in certain areas from mentors, is always important in your community for people to find each other. You might have very interesting skills that other entrepreneurs want to know more about and the same thing happens when you want to meet with coaches, mentors and entrepreneurs.`}</p>
    <p>{`This is what Community Finder is all about, you can browse for all members of the community and Startups.`}</p>
    <h2 {...{
      "id": "sessions"
    }}>{`Sessions`}</h2>
    <p>{`When you find someone that you want to meet, you should be able to book it quickly.`}</p>
    <p>
  If you are in the <span>Events</span> Page, then clicking on{" "}
  <span>Book Meeting</span> will send you to the <span>Community Finder</span>{" "}
  where you can choose with whom you want to have your meeting.
    </p>
    <p>
  From any other place, you need to browse to the <span>Community Finder</span>{" "}
  in your left menu to find whom you want to meet with.
    </p>
    <h2 {...{
      "id": "meeting-notes"
    }}>{`Meeting Notes`}</h2>
    <p>{`The meeting notes is your log for everything that has been discussed and agreed during your meetings with a coach, mentor, or entrepreneur.`}</p>
    <p>{`The sections are distributed as follows:`}</p>
    <p><strong parentName="p">{`Meeting notes section`}</strong>{` - In this section you will find:`}</p>
    <ul>
      <li parentName="ul">{`Meeting Subject`}</li>
      <li parentName="ul">{`Date and Time of the meeting`}</li>
      <li parentName="ul">{`Meeting location (link)`}</li>
      <li parentName="ul">{`Tags (topics)`}</li>
      <li parentName="ul">{`Description or agenda`}</li>
      <li parentName="ul">{`Attendees`}</li>
      <li parentName="ul">{`Buttons: Cancel, Edit and Schedule follow-up`}</li>
    </ul>
    <p><strong parentName="p">{`Meeting Minutes section:`}</strong>{` a text place where you can enter all the information that has been discussed in the meeting. These notes are privates and only visible by you.`}</p>
    <p><strong parentName="p">{`Tasks section:`}</strong>{` this section is only visible when one of the main attendees is an entrepreneur and s/he has a startup. You can see all the tasks for that startup and create, comment and edit the tasks.`}</p>
    <p><strong parentName="p">{`Scoring and Feedback section:`}</strong>{` scoring will show if the customer has setup an scoring section for meetings. Feedback is a confidential feedback that you can share with the program management team related of the outcome of the session or any other comment.`}</p>
    <h2 {...{
      "id": "edit-a-session"
    }}>{`Edit a session`}</h2>
    <p>{`This allows to re-schedule the meeting or edit the subject, add notes, topics, change date and time.`}</p>
    <h2 {...{
      "id": "cancel-a-session"
    }}>{`Cancel a session`}</h2>
    <p>{`A meeting can be cancelled if the meeting is not going to take place or it didn’t take place.
In the case that the meeting didn’t take place but it was not canceled, we can cancel it if there is no notes saved by any of the attendees.`}</p>
    <h2 {...{
      "id": "setting-up-a-follow-up-session"
    }}>{`Setting up a follow-up session`}</h2>
    <p>{`A follow-up meeting will create another meeting with the same subject and look at the main attendee in the meeting to look at s/he availability.
Topic and description (agenda) is open to be written down.
Any additional attendee invited previously needs to be invited again to the meeting.`}</p>
    <h2 {...{
      "id": "meeting-list"
    }}>{`Meeting list`}</h2>
    <p>
  Browse to <span>Documents &amp; Notes</span> → Meetings
    </p>
    <p>{`In this page you have access to all the meeting lists in descending order by date and time.`}</p>
    <Image src="https://res.cloudinary.com/dnuwcxti0/image/upload/v1611026012/teamcoaches/documentation/2mentoring_sessions_lcxm03.gif" alt="2_Mentoring session" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      